// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBjm2YmsIQ7Gqz8UDlSjmxLTI4TawNFoCE",
    authDomain: "unify-deployment.firebaseapp.com",
    databaseURL: "",
    projectId: "unify-deployment",
    storageBucket: "unify-deployment.appspot.com",
    messagingSenderId: "174243565293",
    appId: "1:174243565293:web:902777cbd9b04091e4c6e4"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
